import i18n from 'i18next';

export const delay = (ms: number | undefined) => new Promise(res => setTimeout(res, ms));

export function getLocale(preferredLang = '') {

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const locale = params.get('l');
    const validLocales = i18n.languages;
    if (locale && validLocales && validLocales.includes(locale)) {
        return locale;
    }
    return preferredLang || 'en';
}

export function getUrlEmail() {
    const searchParams = new URLSearchParams(window.location.search);
    const encodedEmail = searchParams.get('un');
    
    if (!encodedEmail) {
        return '';
    }

    const decodedEmail = decodeBase64(encodedEmail);
    if (!isValidEmail(decodedEmail)) {
        return '';
    }

    return decodedEmail;
}

function decodeBase64(encodedString: string): string {
    try {
        return atob(encodedString);
    } catch (error) {
        console.warn('Failed to decode the provided email.');
        return '';
    }
}

function isValidEmail(email: string): boolean {
    const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return EMAIL_REGEX.test(email);
}

export function getResetToken() {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const resetToken = params.get('j');
    if (resetToken && resetToken !== '' && isJWT(resetToken)) {
        return resetToken
    }
    return '';
}


export const randomId = () => {
    return `${(Math.random() * 200).toString(36).substring(3)}-${Math.floor(Math.random() * 500000)}-${(Math.random() * 200).toString(36).substring(7)}-${Math.floor(Math.random() * 500000)}`
}

function isJWT(token: string) {
    const jwtRegex = /^[A-Za-z0-9-_]+\.[A-Za-z0-9-_]+\.[A-Za-z0-9-_=]+$/;
    return jwtRegex.test(token);
}

export function setAppBackground() {

    const stopColor1 = '#1F94FC';
    const stopColor2 = '#54AEFF';
    const stopColor3 = '#45A0FF';

    const svg = '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="1920" height="1080" viewBox="0 0 1920 1080">' +
        '  <defs>' +
        '    <clipPath id="clip-path">' +
        '      <rect id="Rectangle_56" width="1920" height="1080" fill="none"/>' +
        '    </clipPath>' +
        '    <linearGradient id="linear-gradient" x1="1.098" y1="-0.93" x2="-0.144" y2="1.867" gradientUnits="objectBoundingBox">' +
        '      <stop offset="0.014" stop-color="' + stopColor1 + '"/>' +
        '      <stop offset="0.342" stop-color="' + stopColor2 + '"/>' +
        '      <stop offset="0.721" stop-color="' + stopColor1 + '"/>' +
        '      <stop offset="0.858" stop-color="' + stopColor2 + '"/>' +
        '    </linearGradient>' +
        '    <linearGradient id="linear-gradient-2" x1="-0.068" y1="-0.301" x2="1.41" y2="1.905" xlink:href="#linear-gradient"/>' +
        '    <linearGradient id="linear-gradient-3" x1="0.157" y1="0.238" x2="0.759" y2="1.088" gradientUnits="objectBoundingBox">' +
        '      <stop offset="0" stop-color="' + stopColor1 + '"/>' +
        '      <stop offset="0.102" stop-color="' + stopColor1 + '"/>' +
        '      <stop offset="0.606" stop-color="' + stopColor1 + '"/>' +
        '      <stop offset="1" stop-color="' + stopColor2 + '"/>' +
        '    </linearGradient>' +
        '    <linearGradient id="linear-gradient-4" y1="0.5" x2="1.065" y2="0.618" gradientUnits="objectBoundingBox">' +
        '      <stop offset="0" stop-color="' + stopColor2 + '"/>' +
        '      <stop offset="1" stop-color="' + stopColor3 + '"/>' +
        '    </linearGradient>' +
        '  </defs>' +
        '  <g id="abstract_bg" opacity="0.664">' +
        '    <g id="Group_65" clip-path="url(#clip-path)">' +
        '      <g id="Group_64" >' +
        '        <path id="Path_12077" d="M2117,332" fill="#fff"/>' +
        '        <path id="Path_12078" d="M-185.575,392S542.382,700.032,830.219,757.609c265.1,53.028,1071.928-108.582,1227.531-243.257S1934.805,1165,1934.805,1165L-186,1142.274Z" opacity="0.239" fill="url(#linear-gradient)"/>' +
        '        <path id="Path_12079" d="M-394,500.054S31.432,394.811,242.811,453.676,728,778,960,829c373.618,82.132,540-324,739-338,456.759-32.134,316,445.189,316,445.189L1968.622,1177l-2195.838,14.27Z" opacity="0.186" fill="url(#linear-gradient-2)"/>' +
        '        <path id="Path_12080" d="M2217.295,783c-9-5-645.295-197-1067-214-392.748-15.833-546,146-782,180S-446.446,583-537.576,509s23.871,832,23.871,832l2917.7-71.5Z" opacity="0.203" fill="url(#linear-gradient-3)"/>' +
        '        <path id="Path_12081" d="M2042,72s-245,694-752,773S507,956,146,672-46,1257-46,1257H2072.131Z" opacity="0.251" fill="url(#linear-gradient-4)"/>' +
        '      </g>' +
        '    </g>' +
        '  </g>' +
        '</svg>';

    let encoded = window.btoa(svg);
    document.body.style.backgroundImage = `url(data:image/svg+xml;base64,${encoded})`;
}

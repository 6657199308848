import React, { createContext, useContext, useReducer, ReactNode } from 'react';
import { AuthState, ISettings } from '../interfaces/auth.interface'
import authReducer, { AuthAction } from '../reducers/auth.reducer';

interface AuthProviderProps {
  children: ReactNode;
}

interface AuthContextType extends AuthState {
  dispatch: React.Dispatch<AuthAction>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {

  const initialState: AuthState = {
    email: '',
    password: '',
    code: '',
    rememberDevice: false,
    loading: false,
    settings: {} as ISettings
  };

  const [state, dispatch] = useReducer(authReducer, initialState);

  const contextValue: AuthContextType = {
    ...state,
    dispatch,
  };

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
import React, { useState, useEffect } from 'react';
import { useAuth } from '../../context/auth.context';
import { Form, InputGroup, Button } from 'react-bootstrap';
import i18n from '../../i18n';
import { updateEmail, setLoading, updateSettings } from '../../actions/auth.action'
import { ISettings } from '../../interfaces/auth.interface';
import { LoginMode } from '../../enums/auth.enum';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/pro-light-svg-icons";
import { faSpinner } from '@fortawesome/pro-duotone-svg-icons';
import apiService from '../../services/api';
import { getUrlEmail } from '../../services/utils';

const EmailStepComponent: React.FC = () => {

  const api = new apiService()
  const { t } = i18n
  const { email, settings, loading, dispatch } = useAuth();
  const [formValidated, setFormValidated] = useState(false);

  useEffect(() => {
    const decodedEmail = getUrlEmail();
    if (decodedEmail) {
      dispatch(updateEmail(decodedEmail));
    }
  }, [dispatch]);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
    setFormValidated(false);
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {

      try {

        dispatch(setLoading(true));
        dispatch(updateSettings({ ...settings, error: ''}))

        // Make API call to validate email
        const resp = await api.validateEmail({ email })

        if (resp.status === 204) {
          dispatch(updateSettings({
            loginMode: LoginMode.BASIC,
            emailVerified: false,
            emailConfirmed: true
          }));

        } else if (resp.status === 200) {
          dispatch(updateSettings(resp.data as ISettings))
        } else {
          throw new Error();
        }

      } catch (error) {
        dispatch(updateSettings({ error: t('_system_error') }))
      } finally {
        dispatch(setLoading(false));
      }
    }
    setFormValidated(true);
  }

  if (settings.emailConfirmed && settings.passwordConfirmed) {
    return (<></>)
  }

  return (
    <>
      <Form noValidate validated={formValidated} onSubmit={handleSubmit}>
        <InputGroup className="mb-2">
          <InputGroup.Text><FontAwesomeIcon icon={faUser} /></InputGroup.Text>
          <Form.Control
            placeholder={t('email')}
            aria-label="Email"
            required
            type="email"
            onChange={(e) => dispatch(updateEmail(e.target.value))}
            id={'username'}
            autoComplete={'username'}
            defaultValue={email}
            disabled={loading}
            readOnly={loading}
            autoFocus
          />
          <Form.Control.Feedback type="invalid">
            {email ? `${t('invalid_field', { field: t('email') })}: ${email}` : t('field_cannot_be_blank', { field: t('email') })}
          </Form.Control.Feedback>

        </InputGroup>


        {!settings.emailConfirmed &&
          <Button variant="primary" type="submit" disabled={loading} className="mt-3">
            {loading && <FontAwesomeIcon icon={faSpinner} className="fa-spin" />}
            {t('continue')}
          </Button>
        }
      </Form>
    </>
  );
};

export default EmailStepComponent;
